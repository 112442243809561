label {
	  display: inline-block;
	  width: 20em;
}

.grid_container {
	display: grid;
	height: 100vh;
	grid-template-columns: 800px 800px;
	grid-template-areas:
		"canvas source"
		"errors errors"
}

.source {
	overflow-y: scroll
}
